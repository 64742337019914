.display {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
}

.beerCard {
    margin: 15px 0 10px 5%;
    height: auto;
    width: 90%;
    background-color: rgba($color: #d2dde034, $alpha: 0.5);
    border-bottom: #10759d;
    box-shadow: 2px -1px 2px 1px rgba(203, 203, 213, 0.789);
    border-radius: 50px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    
    img{
        background-color: transparent;
        margin: 15px;
        width: auto;
        height: 200px;
    }

    .textArea {
        color: white;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75%;
        background-color: #10759d;
        border-top-left-radius: 30%;
        border-top-right-radius: 30%;


        h2 {
            color: black;
            width: 68%;
            text-align: center;
            font-size: 17px;
            margin: 30px 0 10px 0;
        }
    
        h3 {
            text-align: center;
            width: 90%;
            font-size: 14px;
            margin:0;
            font-style: italic;
        }
    
        p {
            font-size: 13px;
            width: 80%;
            margin: 15px 0 20px 0;
        }

        .abv {
            margin: 10px 0 15px 0;
        }

        
            
    }

    .barArea {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .meter {
            overflow: hidden;
            width: 40%;
            height: 10px; 
            position: relative;
            margin: 10px 0; 
            background: rgb(228, 213, 213);
            border-radius: 25px;
            padding: 6px;
            box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    
            span {
                display: block;
                height: 100%;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                background-color: rgb(43, 194, 83);
                box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
                  inset 0 -2px 6px rgba(0, 0, 0, 0.4);
                position: relative;
                overflow: hidden;
            }
        }
    }
    
    .ebcMeter {
        overflow: hidden;
        width: 20px;
        height: 20px; 
        position: relative;
        margin: 10px 0 ; 
        background: rgb(228, 213, 213);
        border-radius: 100%;
        padding: 3px;
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

        span {
            width: 100%;
            display: block;
            height: 100%;
            border-radius: 100%;
            box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
              inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            position: relative;
            overflow: hidden;
          }
      }
}

@media only screen and (max-width: 1000px) {
    .display {
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
    }

    .beerCard {
        margin: 15px 0 10px 15px;
        height: auto;
        
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        
        img{
            background-color: transparent;
            margin: 15px;
            width: auto;
            height: 200px;
        }
    
        .textArea {
            h2 {
                width: 78%;
                font-size: 13px;
                margin: 30px 0 10px 0;
            }
        
            h3 {
                width: 90%;
                font-size: 10px;
            }
        
            p {
                font-size: 8px;
                margin: 15px 0 20px 0;
            }
        }
    }
} 


@media only screen and (max-width: 750px) {
    .display {
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .beerCard {
        margin: 15px 0 10px 0;

        img{
            height: 150px;
        }
    
        .textArea {
            p {
                font-size: 9px;
            }
        }
    }
} 