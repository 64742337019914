.container {
  font-family: Arial, Helvetica, sans-serif;
  
  display: grid;
  grid-template-columns: 250px 2fr;
  grid-gap: 5px;

  background-color: #ffffff;
}

@media only screen and (max-width: 1025px) {
  .container {
      display: flex;
      overflow-x: hidden;
  }
}