.nav {
    position: fixed;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    grid-column-start: 1;
    grid-column-end: 2;

    margin-left: 3%;

    h1 {
        margin-top: 20%;
    }

}

@media only screen and (max-width: 1024px) {
    .navMob{
        height: 50vh;
        position: fixed;
        background-color: white;
        z-index: 2;


        .sideNav {
            text-align: center;
            height: 100%;;
            width: 0;
            z-index: 1; 
            top: 0; 
            left: 0;
            overflow-x: hidden; 
            padding-top: 60px; 
            transition: 0.8s;
        
            
        }
    }

    h1 {
        text-align: center;
        margin-top: 20%;
        font-size: 25px;
        transition: 0.5s;
    }

    .open {
        background-color: white;
        width: 100%;
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    .closebtn {
        position: absolute;
        top: 5px;
        right: 20px;
        font-size: 30px;
    }

    .openbtn {
        font-size:30px;
        cursor:pointer;
        position: absolute;
        top: 5px;
        left:10px;
        z-index: 1;
    }
}